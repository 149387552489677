.explore-more-hotels .swiper-wrapper,
.home-carousel .swiper-wrapper,
.browse-hotels .swiper-wrapper {
  transition-timing-function: linear;
  align-items: center !important;
}

#home-page-reviews-swiper .swiper-slide {
  height: 220px !important;
}

.home-carousel .desktop .swiper-slide {
  width: auto !important;
}

.home-carousel .mobile .swiper-slide {
  width: 100% !important;
}

.home-carousel.cruise-carousel .mobile .swiper-slide {
  width: auto !important;
}

.home-carousel.cruise-carousel .mobile .swiper-slide {
  height: 100px !important;
}

.next-available .swiper-button-next,
.explore-more-hotels .swiper-button-next,
.home-carousel .swiper-button-next,
.browse-hotels .swiper-button-next,
.dlp-carousel .desktop .swiper-button-next {
  background-color: white;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-image: url('../../../assets/images/chevron-right.svg');
  background-repeat: no-repeat;
  background-size: 10px 12px;
  background-position: center;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%), 0 6px 6px 0 rgb(0 0 0 / 24%);
}

.dlp-carousel.home-near-me-carousel .desktop .swiper-button-next {
  display: none;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-image: url('../../../assets/images/chevron-right.svg');
  background-repeat: no-repeat;
  background-size: 10px 12px;
  background-position: center;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%), 0 6px 6px 0 rgb(0 0 0 / 24%);
}

.dlp-carousel.home-near-me-carousel .desktop .swiper:hover .swiper-button-next {
  display: block;
}

.dlp-carousel .desktop .swiper-button-next,
.dlp-carousel .desktop .swiper-button-prev {
  opacity: 0.9;
}

.dlp-carousel .desktop .error-image {
  transform: scaleY(1.05);
  margin-top: 12px;
}

.dlp-carousel .mobile .error-image {
  transform: scaleY(1.05);
  margin-top: 7px;
}

.next-available .swiper-button-prev,
.explore-more-hotels .swiper-button-prev,
.home-carousel .swiper-button-prev,
.browse-hotels .swiper-button-prev,
.dlp-carousel .desktop .swiper-button-prev {
  background-color: white;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-image: url('../../../assets/images/chevron-left.svg');
  background-repeat: no-repeat;
  background-size: 10px 12px;
  background-position: center;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%), 0 6px 6px 0 rgb(0 0 0 / 24%);
}

.dlp-carousel.home-near-me-carousel .desktop .swiper-button-prev {
  display: none;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-image: url('../../../assets/images/chevron-left.svg');
  background-repeat: no-repeat;
  background-size: 10px 12px;
  background-position: center;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%), 0 6px 6px 0 rgb(0 0 0 / 24%);
}

.dlp-carousel.home-near-me-carousel .desktop .swiper:hover .swiper-button-prev {
  display: block;
}

.next-available .swiper-button-next::after,
.explore-more-hotels .swiper-button-next::after,
.home-carousel .swiper-button-next::after,
.browse-hotels .swiper-button-next::after,
.dlp-carousel .swiper-button-next::after {
  content: '' !important;
}

.next-available .swiper-button-prev::after,
.explore-more-hotels .swiper-button-prev::after,
.home-carousel .swiper-button-prev::after,
.browse-hotels .swiper-button-prev::after,
.dlp-carousel .swiper-button-prev::after {
  content: '' !important;
}

.swiper-pagination-bullet {
  height: 6px !important;
  width: 6px !important;
  background: white !important;
  opacity: 0.7 !important;
}

.swiper-pagination-bullet-active {
  height: 8px !important;
  width: 8px !important;
  background-color: white !important;
  opacity: 1 !important;
}

.cruise-experience .swiper-pagination-bullet {
  height: 6px !important;
  width: 6px !important;
  background: white !important;
  opacity: 0.7 !important;
  margin-bottom: 10px !important;
}

.product-tiles-carousel .swiper-pagination-bullet,
.product-tiles-carousel .swiper-pagination-bullet-active {
  background: #6E8387 !important;
}

.home-near-me-carousel .swiper-pagination-bullet,
.home-near-me-carousel .swiper-pagination-bullet-active {
  background: white !important;
}


.cruise-experience .swiper-pagination-bullet-active {
  height: 8px !important;
  width: 8px !important;
  background-color: white !important;
  opacity: 1 !important;
  margin-bottom: 10px !important;
}

.cruise-reviews .swiper {
  border-radius: 12px !important;
}

@media (max-width: 850px) {
  .home-carousel .swiper-button-next {
    width: 36px;
    height: 36px;
  }

  .home-carousel .swiper-button-prev {
    width: 36px;
    height: 36px;
  }

  #home-page-reviews-swiper .swiper-slide {
    height: 300px !important;
  }

  #home-page-popular-hotels-swiper .swiper-slide {
    height: 350px !important;
  }

  .cruise-experience .swiper-pagination-bullet {
    height: 6px !important;
    width: 6px !important;
    background: white !important;
    opacity: 0.7 !important;
    margin-bottom: -1px !important;
  }

  .cruise-experience .swiper-pagination-bullet-active {
    height: 8px !important;
    width: 8px !important;
    background-color: white !important;
    opacity: 1 !important;
    margin-bottom: -1px !important;
  }

  .cruise-reviews .swiper-pagination-bullet {
    height: 6px !important;
    width: 6px !important;
    background: white !important;
    opacity: 0.7 !important;
    margin-bottom: 8px !important;
  }

  .cruise-reviews .swiper-pagination-bullet-active {
    height: 8px !important;
    width: 8px !important;
    background-color: white !important;
    opacity: 1 !important;
    margin-bottom: 8px !important;
  }
}

/* Explore more Hotels Carousel */
.next-available .swiper-button-next,
.next-available .swiper-button-prev,
.explore-more-hotels .swiper-button-next,
.explore-more-hotels .swiper-button-prev {
  width: 32px;
  height: 32px;
}

/* Browse Hotels Carousel */
.browse-hotels .swiper-button-next,
.browse-hotels .swiper-button-prev {
  width: 36px;
  height: 36px;
}

.next-available .swiper-button-next,
.next-available .swiper-button-prev,
.explore-more-hotels .swiper-button-next,
.explore-more-hotels .swiper-button-prev,
.browse-hotels .swiper-button-next,
.browse-hotels .swiper-button-prev {
  @apply static m-0;
}

.explore-more-hotels .swiper-left .swiper,
.next-available .swiper-left .swiper {
  margin-left: 0 !important;
}

.swiper input {
  caret-color: transparent !important;
}

.dlp-carousel.srp-carousel .swiper-pagination-bullets {
  left: 50px !important;
}

.cruise-carousel .swiper-slide {
  width: 300px !important;
}
