.react-datepicker__input-container > input {
  @apply focus:outline-none mt-1.5;
}

.react-datepicker__input-container > input::placeholder {
  color: grey;
}

/*
______________________________________________________
    Home Page Date Picker
______________________________________________________
*/

#home-page-date-picker {
  width: 80%;
}

#home-page-date-picker .react-datepicker {
  @apply mt-0 pt-4 d:-mt-1;
}

#home-page-date-picker .react-datepicker__navigation {
  @apply mt-7;
}

#home-page-date-picker .react-datepicker__day-names {
  @apply pt-3;
}

#home-page-date-picker .react-datepicker-popper {
  @apply -ml-2 d:-ml-16;
}

#home-page-date-picker .react-datepicker__input-container > input {
  @apply focus:outline-none;
}

#home-page-date-picker .react-datepicker__input-container > input {
  caret-color: transparent !important;
  margin-top: 0px;
}

#home-page-date-picker .react-datepicker {
  transform: scale(0.95);
}

/*
______________________________________________________
    SRP Date Picker
______________________________________________________
*/

.srp-date-picker .react-datepicker__input-container > input {
  @apply mt-1.5;
}

.react-datepicker__children-container {
  width: 100% !important;
}

#scroll-helper {
  position: fixed;
  bottom: 0;
  background-color: transparent;
  width: 100%;
}

#gift-card-purchase .react-datepicker-popper {
  z-index: 999 !important;
}

/*
______________________________________________________
    Home Page Variant - Desktop
______________________________________________________
*/
#home-page-date-picker-variant .react-datepicker {
  @apply w-full;
  box-shadow: none !important;
}

#home-page-date-picker-variant .react-datepicker__input-container > input {
  @apply mt-0 focus:outline-none;
  caret-color: transparent !important;
}

#home-page-date-picker-variant .react-datepicker__month-container {
  @apply mt-6 w-full;
  border: none !important;
  padding-bottom: 0 !important;
}

#home-page-date-picker-variant .react-datepicker__header {
  @apply flex flex-col-reverse p-0;
}

#home-page-date-picker-variant .react-datepicker__day-names {
  @apply justify-items-center grid grid-cols-7 opacity-100 m-0;
}

#home-page-date-picker-variant .react-datepicker__day-name {
  @apply items-center justify-center;

  display: flex !important;
  color: #6e8387;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal !important;
  margin: 0 !important;
  height: 40px !important;
  width: 40px !important;
}

#home-page-date-picker-variant .react-datepicker__navigation {
  top: 75px !important;
}

#home-page-date-picker-variant .react-datepicker__navigation-icon::before {
  content: '';
  background: url(../../../assets/images/next.svg) no-repeat;
  width: 15px;
  height: 15px;
  border: none;
}

#home-page-date-picker-variant .react-datepicker__navigation-icon--next::before {
  transform: none !important;
}

#home-page-date-picker-variant .react-datepicker__navigation-icon--previous::before {
  transform: rotate(180deg);
}

#home-page-date-picker-variant .react-datepicker__current-month {
  @apply mt-2 text-center text-base;
  color: #6e8387;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
}

#home-page-date-picker-variant .react-datepicker__month {
  @apply mt-2 mb-0 mx-0 p-0 w-full grid grid-rows-6 gap-2;
}

#home-page-date-picker-variant .react-datepicker__week {
  @apply justify-items-center grid grid-cols-7 w-full;
}

#home-page-date-picker-variant .react-datepicker__day {
  @apply flex items-center justify-center text-base;
  margin: 0 !important;
  height: 40px !important;
  width: 40px !important;
  color: #252525;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#home-page-date-picker-variant .react-datepicker__day--selected {
  height: 40px !important;
  width: 40px !important;
  background-color: #252525 !important;
  color: #ffffff !important;
  border-radius: 6px !important;
}

#home-page-date-picker-variant .react-datepicker__day:hover {
  border-radius: 6px !important;
  margin: 0 !important;
  height: 40px !important;
  width: 40px !important;
}

#home-page-date-picker-variant .react-datepicker__day--outside-month {
  visibility: hidden;
}

/*
______________________________________________________
    Home Page Variant - Mobile
______________________________________________________
*/
#home-page-mobile-date-picker-variant .react-datepicker {
  @apply w-full;
  box-shadow: none !important;
}

#home-page-mobile-date-picker-variant .react-datepicker__input-container > input {
  @apply mt-0 focus:outline-none;
  caret-color: transparent !important;
}

#home-page-mobile-date-picker-variant .react-datepicker__month-container {
  @apply w-full;
  border: none !important;
  padding-bottom: 0 !important;
}

#home-page-mobile-date-picker-variant .react-datepicker__header {
  @apply flex flex-col-reverse p-0;
}

#home-page-mobile-date-picker-variant .react-datepicker__day-names {
  @apply justify-items-center grid grid-cols-7 opacity-100 m-0;
}

#home-page-mobile-date-picker-variant .react-datepicker__day-name {
  @apply items-center justify-center;

  display: flex !important;
  color: #6e8387;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal !important;
  margin: 0 !important;
  height: 40px !important;
  width: 40px !important;
}

#home-page-mobile-date-picker-variant .react-datepicker__navigation {
  visibility: hidden !important;
}

#home-page-mobile-date-picker-variant .react-datepicker__current-month {
  @apply ml-4 mt-1 text-left text-base;
  color: #6e8387;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
}

#home-page-mobile-date-picker-variant .react-datepicker__month {
  @apply mt-1 mb-3 mx-0 p-0 w-full grid grid-rows-6 gap-2;
}

#home-page-mobile-date-picker-variant .react-datepicker__week {
  @apply justify-items-center grid grid-cols-7 w-full;
}

#home-page-mobile-date-picker-variant .react-datepicker__day {
  @apply flex items-center justify-center text-base;
  margin: 0 !important;
  height: 40px !important;
  width: 40px !important;
  color: #252525;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#home-page-mobile-date-picker-variant .react-datepicker__day--selected {
  height: 40px !important;
  width: 40px !important;
  background-color: #252525 !important;
  color: #ffffff !important;
  border-radius: 6px !important;
}

#home-page-mobile-date-picker-variant .react-datepicker__day:hover {
  border-radius: 6px !important;
  margin: 0 !important;
  height: 40px !important;
  width: 40px !important;
}

#home-page-mobile-date-picker-variant .react-datepicker__day--outside-month {
  visibility: hidden;
}
